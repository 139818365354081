import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Annual and Sustainability Report"/>
            <Banner title="Annual and Sustainability Report"/>
            <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">IRStrat offers an integral portfolio of services required for the production
                            of a robust and successful annual sustainability report, capable of effectively conveying in
                            a graphic, interactive and textual way your company’s message to your investors and
                            stakeholders, building value-based corporate narratives that drive action and create a
                            positive perception.</p>
                        <p className="f_16">Value proposition: We have a strong advantage over our competitors who
                            mostly focus on graphic design, derived from the synergies of our core business in investor
                            relations consulting and financial lobbying. We have the ability to put at your service our
                            senior team of specialists in financial communications and sustainability, expert
                            translators and top-tier editors, in addition to our staff of designers and programmers.</p>
                    </div>
                    <div className="row servicios">
                        <div className="col-lg-12">
                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion">
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse1">
                                                <span className="pluse">+</span><span className="minus">-</span>Graphical
                                                development</a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body">
                                           

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>General and graphical concept
                                                    of the report
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Development of design proposals
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Design optimization</li>
                                                <li><i className="fa fa-angle-right"></i>Graphic production (Spanish and
                                                    English)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Print monitoring</li>
                                                <li><i className="fa fa-angle-right"></i>Development of PDF (download
                                                    version)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse2"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse2">
                                                <span className="pluse">+</span><span className="minus">-</span>Web
                                                development</a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Development of the microsite
                                                    architecture
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Development of graphical
                                                    interphase
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Development of homepage and
                                                    download center
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Content programming (Spanish
                                                    and English)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse3"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Spanish
                                                content</a>
                                        </h4>
                                    </div>
                                    <div id="collapse3" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Continuity with previous
                                                    reports
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Production logistics</li>
                                                <li><i className="fa fa-angle-right"></i>Information collection</li>
                                                <li><i className="fa fa-angle-right"></i>Structuring</li>
                                                <li><i className="fa fa-angle-right"></i>Content writing</li>
                                                <li><i className="fa fa-angle-right"></i>Updating of sustainability
                                                    section
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse4"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>English
                                                content</a>
                                        </h4>
                                    </div>
                                    <div id="collapse4" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Continuity in style</li>
                                                <li><i className="fa fa-angle-right"></i>Complete translation</li>
                                                <li><i className="fa fa-angle-right"></i>Executive officer and IRStrat
                                                    analyst circle-up
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Expert translator circle-up
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Typos cleaning and style
                                                    corrections
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse5"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Sustainability
                                                package</a>
                                        </h4>
                                    </div>
                                    <div id="collapse5" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">
                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Definition of material aspects
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Definition of impact areas</li>
                                                <li><i className="fa fa-angle-right"></i>Definition of relevant
                                                    indicators
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Structuring and depth of the
                                                    report
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Compliance with newest GRI</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
}

export default Contenido
